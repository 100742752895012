<template>
  <loader :isLoading="isLoading" />
  <lf-table
    @changePage="changePage"
    @changeItemsPerPage="changePerPage"
    v-if="userData.data.length"
    :columns="columns"
    :data="userData.data"
    :metadata="userData.meta"
  />
  <lf-card v-else class="py-6 mb-8">
    <div class="flex items-center justify-center">
      {{ $t("FUNDERS.USER_LIST_EMPTY") }}
    </div>
  </lf-card>
</template>
<script setup>
import { computed, onMounted, onUnmounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { getParamsWithPagination } from "@/helpers/pagination";
import { useRoute } from "vue-router";
import { useAuth } from "@/hooks/auth";

import UserRoles from "@/components/UserRoles.vue";
import UserEmail from "@/components/UserEmail.vue";
import router from "@/router";
import UserMenuButton from "@/views/profile/components/UserMenuButton.vue";
import LfTable from "@/components/ui/table/LfTable.vue";

const props = defineProps({
  search: {
    type: String,
    default: ""
  }
});

const { dispatch, getters, commit } = useStore();
const { t } = useI18n();
const route = useRoute();
const { isFunderAdmin, isAdmin } = useAuth();

const page = ref(1);
const per_page = ref(15);
const isLoading = ref(false);

const userData = computed(() => getters["funders/funderUsers"]);

const showFunderMenuButton = computed(() => isFunderAdmin || isAdmin);

const getUsers = async () => {
  isLoading.value = true;

  const query = getParamsWithPagination({ search: props.search || null });

  router.push({ query });
  await dispatch("funders/getUsers", query);
  isLoading.value = false;
};

const changePage = (newPage) => {
  page.value = newPage;
  getUsers();
};

const changePerPage = (perPage) => {
  per_page.value = perPage;
  getUsers();
};

watch(
  () => props.search,
  () => getUsers()
);

onMounted(async () => {
  if (route.name === "FunderOrganization") {
    await dispatch("auth/getAuthDetails");
  }
  await dispatch("funders/checkHasFunderAdmin");
  getUsers();
});

onUnmounted(() => commit("funders/unsetFunderUsers"));

const columns = computed(() =>
  [
    {
      label: t("COMMON.USER"),
      component: "user-profile"
    },
    {
      label: t("COMMON.ROLE"),
      component: UserRoles
    },
    {
      label: t("COMMON.EMAIL"),
      component: UserEmail
    },

    showFunderMenuButton.value
      ? {
          label: "",
          component: UserMenuButton,
          props: { menuType: "funders" }
        }
      : null
  ].filter(Boolean)
);
</script>
